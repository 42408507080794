<template>
  <div v-if="dataReady" class="onboarding-review-admin onboarding-review-admin-page">
    <vs-row id="profile-page" class="mt-5" v-if="isReady">
      <!-- Business Information -->
      <vx-card class="accordin-card">
        <div class="vx-card__body bsv p-0">
          <vs-collapse accordion>
            <vs-collapse-item ref="businessInfoCollapse" :open="isReady">
              <div slot="header">
                <div class="flex items-center">
                  <div class="icon-wrapper">
                  <img class="icon-control" :src="edit" alt="edit">
                  </div>
                  <h3 class="text-left card-title  font-normal lg:text-2xl md:text-xl text-lg">Business information</h3>
                  <div class="ml-auto"></div>
                </div>
              </div>

              <vs-row>
                <!-- General Information -->
                <div class="w-full mb-8">
                  <div class="flex mb-2 items-center ml-1">
                    <div class="flex justify-center items-center" style="max-width:20px;">
                      <img class="icon-control" :src="editlight" alt="edit">
                    </div>
                    <h4 class="text-xl mb-0 font-normal ml-5">General information</h4>
                  </div>
                  <hr class="line-hr mb-8 md:mb-10 w-full ml-1">

                  <GeneralInformation
                    :generalInfo="onboardDetail.businessInfo"
                  />
                </div>

                <div class="w-full mb-8">
                  <div class="flex mb-2 items-center ml-1">
                    <div class="flex justify-center items-center" style="max-width:20px;">
                      <img class="icon-control" :src="editlight" alt="edit">
                    </div>
                    <h4 class="text-xl mb-0 font-normal ml-5">Business profile</h4>
                  </div>
                  <hr class="line-hr w-full ml-1">
                  <vs-row>
                    <div class="w-full">
                      <BusinessProfile
                        :businessProfile="onboardDetail.businessProfile"
                        :onboardingCustomFields="onboardDetail.onboardingCustomFields"
                      />
                    </div>
                  </vs-row>
                </div>

                <!-- Contact details -->
                <div class="w-full mb-8">
                  <div class="flex mb-2 items-center ml-1">
                    <div class="flex justify-center items-center" style="max-width:20px;">
                      <img class="icon-control" :src="editlight" alt="edit">
                    </div>
                    <h4 class="text-xl mb-0 font-normal ml-5">Contact details</h4>
                  </div>
                  <hr class="line-hr mb-8 w-full ml-1" />
                  <ContactDetails
                    :contactDetails="onboardDetail.contactDetails"
                    :onboardingRequestId="onboardingId"
                    :applicationStatus="onboardDetail.status"
                  />
                </div>
              </vs-row>
            </vs-collapse-item>
          </vs-collapse>
        </div>
      </vx-card>

      <!-- Business ownership -->
      <vx-card class="accordin-card mt-6" id="businessOwnershipArea">
        <div class="vx-card__body bsv p-0">
          <vs-collapse accordion>
            <vs-collapse-item ref="businessOwnership" :open="isReady">
              <div slot="header">
                <div class="flex flex-row items-center">
                  <div class="icon-wrapper">
                    <img class="icon-control" :src="bank" alt="bank">
                  </div>
                  <h3 class="text-left card-title  font-normal lg:text-2xl md:text-xl text-lg">Business ownership</h3>
                </div>
              </div>
              <vs-row>
                <div class="w-full">
                  <BusinessOwnership
                    ref="businessOwnershipChild"
                    :onboardingRequestId="onboardingId"
                    :entityList="onboardDetail.businessOwnership"
                    :businessOwnershipCustomFields="businessOwnershipCustomFields"
                  />
                </div>
              </vs-row>
            </vs-collapse-item>
          </vs-collapse>
        </div>
      </vx-card>

      <!-- Bank Account -->
      <vx-card class="accordin-card mt-4">
        <div class="vx-card__body bsv p-0">
          <vs-collapse accordion>
            <vs-collapse-item ref="bankCollapse" :open="isReady">
              <div slot="header">
                <div class="flex items-center">
                  <div class="icon-wrapper">
                    <img class="icon-control" :src="bank" alt="bank">
                  </div>
                  <h3 class="text-left card-title  font-normal lg:text-2xl md:text-xl text-lg">Bank accounts</h3>
                </div>
              </div>
              <vs-row>
                <div class="w-full">
                  <BankAccounts 
                    :bankAccount="onboardDetail.bankAccount"
                    :entityList="onboardDetail.businessOwnership"
                   />
                </div>
              </vs-row>
            </vs-collapse-item>
          </vs-collapse>
        </div>
      </vx-card>

      <!-- Product Selection -->
      <vx-card class="accordin-card mt-4">
        <div class="vx-card__body bsv p-0">
          <vs-collapse accordion>
            <vs-collapse-item ref="templateCollapse" :open="isReady">
              <div slot="header">
                <div class="flex items-center">
                  <div class="icon-wrapper">
                    <img class="icon-control" :src="product" alt="product">
                  </div>
                  <h3 class="text-left card-title  font-normal lg:text-2xl md:text-xl text-lg">Product selection</h3>
                </div>
              </div>

              <div>
                <ProductSelection
                  :product="onboardDetail.productSelection"
                />
              </div>
            </vs-collapse-item>
          </vs-collapse>
        </div>
      </vx-card>

      <!-- Onboarding Agreement -->
      <vx-card class="accordin-card mt-4" v-if="legalsReady">
        <div class="vx-card__body bsv p-0">
          <vs-collapse accordion>
            <vs-collapse-item ref="businessInfoCollapse" :open="isReady">
              <div slot="header">
                <div class="flex items-center">
                  <div class="icon-wrapper">
                    <img class="icon-control" :src="edit" alt="home" />
                  </div>
                  <h3 class="text-left card-title font-normal lg:text-2xl md:text-xl text-lg">{{ currentLegal.documentName }}</h3>
                  <div class="ml-auto"></div>
                </div>
              </div>

              <div class="w-full">
                <OnboardingAgreement
                  :legalsInfo="currentLegal"
                  :terms="onboardDetail.terms"
                />
              </div>
            </vs-collapse-item>
          </vs-collapse>
        </div>
      </vx-card>
    </vs-row>
    <div class="mt-10" v-if="dataError">
      <div class="vx-col flex items-center justify-center flex-col sm:w-1/2 md:w-3/5 lg:w-3/4 xl:w-1/2 mx-auto text-center">
        <img src="@/assets/images/pages/404.png" alt="graphic-404" class="mx-auto mb-4 max-w-full"/>
        <h1 class="sm:mx-0 mx-4 mb-12 text-5xl d-theme-heading-color">404 - Data Not Found!</h1>

        <vs-button size="large" @click="goToDashboard">Back to Home</vs-button>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions } from "vuex";
import GeneralInformation from "./businessInformation/GeneralInformation.vue";
import BusinessProfile from "./businessInformation/BusinessProfile.vue";
import ContactDetails from "./businessInformation/ContactDetails.vue";
import BusinessOwnership from "./businessOwnership/BusinessOwnership";
import BankAccounts from "./bankAccount/BankAccounts.vue";
import ProductSelection from "./product/ProductSelection.vue";
import OnboardingAgreement from "./agreement/OnboardingAgreement.vue";
const editlight = require("@/assets/images/edit-grey.svg");
const bank = require("@/assets/images/bank.svg");
const product = require("@/assets/images/product.svg");
const edit = require("@/assets/images/edit.svg");
import S3FileGenerator from "../../components/S3FileGenerator";

export default {
  name: "index",
  components: {
    GeneralInformation,
    BusinessProfile,
    ContactDetails,
    BusinessOwnership,
    BankAccounts,
    ProductSelection,
    S3FileGenerator,
    OnboardingAgreement,
  },
  data() {
    return {
      isReady: false,
      dataReady: false,
      dataError: false,
      filePath: process.env.VUE_APP_API_URL + "uploads/exports/",
      onboardingId: "",
      onboardDetail: {},
      editlight: editlight,
      bank: bank,
      product: product,
      edit: edit,
      businessOwnershipCustomFields: [],
      legalId: "",
      currentLegal: {},
      legalsReady: false,
      appUrl: process.env.VUE_APP_BASE_URL,
    };
  },

  mounted() {
    this.onboardingId = this.$route.params.onboardingId;
    if (this.$refs.businessInfoCollapse)
      this.$refs.businessInfoCollapse.maxHeight = "none !important";
  },
  methods: {
    ...mapActions("onboardingAdmin", ["fetchOnboardReviewDetailWithId"]),
    async getDetail() {
      try {
        this.$vs.loading();
        await this.fetchOnboardReviewDetailWithId(
          this.$route.params.onboardingId
        )
          .then((result) => {
            if (result) {
              this.onboardDetail = result.data.data;
              this.userId = result.data.data.userId;
              this.businessOwnershipCustomFields =
                result.data.data.onboardingRequest.businessOwnershipFields;
              if (result.data.data.terms.hasOwnProperty("generalTermsId")) {
                this.legalId = result.data.data.terms.generalTermsId;
                this.getTerms();
              }
              this.isReady = true;
              this.dataReady = true;
            } else {
              this.dataReady = true;
              this.dataError = true;
            }
            this.$vs.loading.close();
          })
          .catch((err) => {
            this.$vs.loading.close();
            this.dataReady = true;
            this.dataError = true;
            this.$vs.notify({
              title: "Error",
              text: "Error fetching details",
              iconPack: "feather",
              icon: "icon-alert-circle",
              color: "danger",
            });
          });
      } catch (e) {
        this.$vs.notify({
          title: "Error",
          text: "Error fetching details",
          iconPack: "feather",
          icon: "icon-alert-circle",
          color: "danger",
        });
      }
    },
    goToDashboard() {
      this.$router.push({ name: "dashboard" });
    },
    getTerms() {
      this.$store
        .dispatch("legals/fetchAllLegals", "DASHBOARD")
        .then((result) => {
          this.$vs.loading.close();

          const legals = result.data.data.legals;
          const latestPrivacy = result.data.data.privacy;
          const latestWebsiteTerms = result.data.data.websiteTerms;
          this.currentLegal = legals.find((el) => el._id === this.legalId);

          if (this.currentLegal.explicitTerms) {
            this.currentLegal.explicitTerms = this.replaceLegalContent(
              this.currentLegal.explicitTerms,
              {
                fullTerms: `<a href="${this.appUrl}terms-view/${this.currentLegal._id}?type=DASHBOARD" target="_blank">${this.currentLegal.documentName}</a>`,
                privacy: `<a href="${this.appUrl}terms-view/${latestPrivacy._id}?type=DASHBOARD&fullDocument=true" target="_blank">${latestPrivacy.documentName}</a>`,
                generalWebsiteTerms: `<a href="${this.appUrl}terms-view/${latestWebsiteTerms._id}?type=DASHBOARD&fullDocument=true" target="_blank">${latestWebsiteTerms.documentName}</a>`,
              }
            );
            this.legalsReady = true;
          }
        })
        .catch((err) => {
          this.$vs.loading.close();
          this.$vs.notify({
            title: "Error",
            text: "Error fetching legals",
            iconPack: "feather",
            icon: "icon-alert-circle",
            color: "danger",
          });
        });
    },
  },

  beforeMount() {
    this.getDetail();
  }
};
</script>