<template>
  <div>
    <div v-for="(entityDetail, countent) in entityList" :key="countent">
      <vs-row>
        <div class="w-full mb-8">
          <div class="flex mb-4 items-center ml-1">
            <img :src="building" alt="building">
            <h4 class="lg:text-xl md:text-lg text-sm mb-0 ml-5">Legal entity {{ countent + 1 }}</h4>
          </div>
          <hr class="line-hr mb-8" />
          <div class="onboarding-form">
            <div>
              <div class="flex items-center flex-wrap">
                <div class="input-grp">
                  <label class="vs-input--label p-0 w-full review-label">Entity type</label>
                  <p class="textBlack">{{ entityDetail.entityType }}</p>
                </div>
              </div>
              <div v-if="entityDetail.entityType === 'Company'">
                <div class="flex items-center flex-wrap">
                  <div class="input-grp mt-5">
                    <label class="vs-input--label p-0 w-full review-label">Company name</label>
                    <p class="textBlack">{{ entityDetail.companyName }}</p>
                  </div>
                </div>
              </div>
              <div>
                <div class="flex flex-wrap" v-if="entityDetail.entityType === 'Company'">
                  <div class="input-grp mt-5">
                    <label class="vs-input--label p-0 w-full review-label">ACN</label>
                    <p class="textBlack">{{ entityDetail.acn }}</p>
                  </div>

                  <div class="input-grp mt-5" v-if="entityDetail.abn">
                    <label class="vs-input--label p-0 w-full review-label">ABN (if registered under this company)</label>
                    <p class="textBlack">{{ entityDetail.abn }}</p>
                  </div>
                </div>

                <div class="flex flex-wrap" v-if="entityDetail.entityType === 'Individual'">
                  <div class="input-grp mt-5">
                    <label class="vs-input--label p-0 w-full review-label">Full name</label>
                    <p class="textBlack">{{ entityDetail.fullName }}</p>
                  </div>

                  <div class="input-grp mt-5" v-if="entityDetail.abn">
                    <label class="vs-input--label p-0 w-full review-label">ABN (if registered under this individual)</label>
                    <p class="textBlack">{{ entityDetail.abn }}</p>
                  </div>
                </div>

                <div class="flex flex-wrap">
                  <div class="input-grp mt-5" v-if="entityDetail.entityType === 'Individual'">
                    <label class="vs-input--label p-0 w-full review-label">Mobile phone number</label>
                    <p class="textBlack">{{ entityDetail.mobileNumber }}</p>
                  </div>

                  <div class="input-grp mt-5" v-if="entityDetail.entityType === 'Individual'">
                    <label class="vs-input--label p-0 w-full review-label">Email address</label>
                    <p class="textBlack">{{ entityDetail.emailAddress }}</p>
                  </div>
                </div>
              </div>
            </div>

            <div v-if="entityDetail.entityType">
              <div class="mt-10 mb-4">
                <div class="flex mt-6 flex-wrap">
                  <div class="input-grp">
                    <label class="text-base font-normal w-full review-label">Registered address</label>
                    <p class="textBlack">{{ getRegisteredAddress(entityDetail.registeredAddress) }}</p>
                  </div>

                  <div class="input-grp">
                    <label class="text-base font-normal w-full review-label">Trading address</label>
                    <p class="textBlack">{{ getRegisteredAddress(entityDetail.tradingAddress) }}</p>
                  </div>
                </div>
              </div>
            </div>

            <div class="mt-10 flex flex-wrap"
              v-if="
                entityDetail.entityType !== '' &&
                'boCustomFields' in entityDetail &&
                Array.isArray(entityDetail.boCustomFields) &&
                entityDetail.boCustomFields.length > 0
              ">
              <div v-for="(boFields, boIndexNumber) in entityDetail.boCustomFields" :key="boIndexNumber" class="input-grp">
                <div class="mb-5" v-if="matchEntityWithBoAndCustomCf(countent, boIndexNumber)">
                  <label class="vs-input--label w-full review-label">{{ boFields.label }}</label>
                  <p class="textBlack" v-if="boFields.dataType === 'currency'">$ {{ formatToCurrency(boFields.value) }}</p>
                  <p class="textBlack" v-else-if="boFields.dataType === 'number'">{{ formatToCurrency(boFields.value) }}</p>
                  <p class="textBlack" v-else>{{ boFields.value }}</p>
                </div>
              </div>
            </div>

            <div v-if="entityDetail.entityType != ''">
              <div class="mt-10 mb-4">
                <label class="text-base font-normal w-full review-label">Is this entity acting on behalf of a Trust?</label>
                <div class="flex my-1">
                  <div class="mt-4 w-2/12 flex justify-between">
                    <div class="textBlack flex justify-between mb-4">{{entityDetail.isEntityActAsTrust === "yes" ? "Yes" : "No"}}</div>
                  </div>
                </div>

                <div v-if="entityDetail.isEntityActAsTrust == 'yes' || entityDetail.isEntityActAsTrust == true" class="flex items-center flex-wrap">
                  <div class="input-grp">
                    <label class="vs-input--label p-0 w-full review-label">Trust name</label>
                    <p class="textBlack">{{ entityDetail.trustName }}</p>
                  </div>

                  <div class="input-grp" v-if="entityDetail.trustAbn">
                    <label class="vs-input--label p-0 w-full review-label">ABN (if registered under this Trust)</label>
                    <p class="textBlack">{{ entityDetail.trustAbn }}</p>
                  </div>

                  <div class="input-grp mt-5 mb-8">
                    <label class="vs-input--label p-0 w-full review-label">Trust deed</label>
                    <S3FileGenerator
                      :document="
                        typeof entityDetail.trustDeedFiles === 'string'
                          ? entityDetail.trustDeedFiles
                          : entityDetail.trustDeedFiles !== undefined
                          ? entityDetail.trustDeedFiles.name
                          : null"
                    />
                  </div>
                </div>

                <div v-if="entityDetail.entityType === 'Company'">
                  <h3 class="text-base font-normal mt-8">Beneficial owners</h3>
                  <hr class="line-hr mt-4 mb-6" />

                  <div class="mb-8" v-for="(bodetail, boindex) in entityDetail.beneficialOwners" :key="boindex">
                    <div class="">
                      <h3 class="text-base font-normal">Beneficial owner {{ parseInt(boindex) + 1 }}</h3>
                    </div>

                    <div class="input-grp mt-3">
                      <label class="vs-input--label p-0 w-full review-label">Full name</label>
                      <p class="textBlack">{{ bodetail.fullName }}</p>
                    </div>

                    <div class="flex items-center flex-wrap mt-3">
                      <div class="input-grp">
                        <label class="vs-input--label p-0 w-full review-label">Mobile phone number</label>
                        <p class="textBlack">{{ bodetail.mobileNumber }}</p>
                      </div>

                      <div class="input-grp">
                        <label class="vs-input--label p-0 w-full review-label">Email address</label>
                        <p class="textBlack">{{ bodetail.email }}</p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </vs-row>
    </div>
  </div>
</template>

<script>
import S3FileGenerator from "../../../components/S3FileGenerator";
const building = require("@/assets/images/building.svg");

export default {
  name: "BusinessEntity",
  components: {
    S3FileGenerator,
  },
  props: {
    entityList: { type: Array },
    businessOwnershipCustomFields: { type: Array },
  },
  data() {
    return {
      building: building,
    };
  },
  methods: {
    formatToCurrency(amount) {
      if (!amount) return amount;
      let formatter = new Intl.NumberFormat("en-US", {
        style: "currency",
        currency: "USD",
        minimumFractionDigits: 0,
        maximumFractionDigits: 0,
      });
      return formatter.format(amount).replace("$", "");
    },

    matchEntityWithBoAndCustomCf(boIndex, cfIndex) {
      const boEntityType = this.entityList[boIndex].entityType;
      const cfEntityType = this.businessOwnershipCustomFields[cfIndex].entity;
      if (cfEntityType.includes(boEntityType.toLowerCase())) {
        return true;
      }
      return false;
    },

    getRegisteredAddress(data) {
      let addressData = [];
      if (data.appartmentDetail) {
        addressData.push(data.appartmentDetail);
      }
      if (data.streetAddress) {
        addressData.push(data.streetAddress);
      }
      if (data.suburb) {
        if (data.state) {
          addressData.push(data.suburb + " " + data.state);
        } else {
          addressData.push(data.suburb);
        }
      }
      if (data.country) {
        addressData.push(data.country);
      } else {
        addressData.push("Australia");
      }
      return addressData.join(", ");
    },
  },
};
</script>
